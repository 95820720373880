/* COLOR PALETTE
https://coolors.co/c0caad-9da9a0-654c4f-b26e63-cec075
#CB997E - warm brown
#DDBEA9 - light brown
#FFE8D6 - very light tan
#B7B7A4 - light green
#A5A58D - mid green
#6B705C - deeper green
*/

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;500;600;700&display=swap');

body {
  color: #4E3D42;
  margin: 0;
  padding: 0;
  font-family: 'Cabin', sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

svg {
  transition: all .2s ease-in;
}

svg:hover {
  fill: #BD9A7A;
  stroke: #BD9A7A;
}

html {
  scroll-behavior: smooth;
}
